import {
    useStaffDispatchContext,
    useStaffReleaseFormContext,
} from 'src/hooks/useStaffReleaseForm'
import Table from '../Table'
import { Spacer, TableData, TableText } from '../Table.styled'
import { tableHeadings } from './ItemsTable.data'
import { ReactComponent as EditIcon } from 'src/assets/edit.svg'
import { ItemsTableProps } from './ItemsTable.types'
import {
    FlexContainer,
    Image,
    ImageContainer,
    ItemContainer,
} from './ItemsTable.styled'
import { ReactComponent as RemoveIcon } from 'src/assets/remove-icon.svg'
import {
    deleteItem,
    setItems,
} from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext.services'
import { Item } from 'src/types/ReleaseRecord'
import FormButton from 'src/components/common/FormButton/FormButton'
import { omit } from 'lodash'
import { notify } from 'src/utils/notify'
import { copyText, pasteText } from 'src/utils/clipboard'
import { ItemSchema } from 'src/schema/ItemSchema'
import { checkDuplicatesWithinArray } from 'src/utils/checkDuplicate'
import { validateArrayForm } from 'src/utils/validateForm'
import styled from 'styled-components'
import { useState } from 'react'

const SCRIPTS = {
    'Kith Script': `// KITH SCRIPT

const collectionItemsGrid = document.querySelector('[class="shopify-section"]');
const collectionItems = collectionItemsGrid.querySelectorAll('[class="product-card"]');

let formattedList = [];

collectionItems.forEach((item) => {
    let itemTitle = item.querySelector('[class="product-card__title"]').innerText;
    let itemImage = item.querySelector('img').src;
    let itemUrl = item.querySelector('a').href;

    formattedList.push({
          brand: "Kith",
          category: undefined,
          image: itemImage,
          title: itemTitle,
          retail: 0,
          url: itemUrl,
    });
});

copy(formattedList);`,
}

const ScriptMenu = styled.div<{ isOpen: boolean }>`
    position: absolute;
    bottom: 100%;
    right: 0;
    background: #2a2a2a;
    border-radius: 8px;
    padding: 8px;
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
`

const ScriptButton = styled.button`
    width: 100%;
    padding: 8px 16px;
    background: none;
    border: none;
    color: white;
    text-align: left;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
        background: #3a3a3a;
    }
`

const ItemsTable = ({ toggleModal, setSelectedItem }: ItemsTableProps) => {
    const dispatch = useStaffDispatchContext()
    const {
        formState: { items },
    } = useStaffReleaseFormContext()
    const [scriptMenuOpen, setScriptMenuOpen] = useState(false)

    const handleAddItem = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()
        setSelectedItem({} as Item)
        toggleModal()
    }

    const handleDeleteItem =
        (title: string) =>
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault()
            deleteItem(dispatch)(title)
        }

    const handleExport = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()

        try {
            const filteredItems = items.map((item) => {
                if (item.title == null) {
                    return omit(item, ['exists', 'title'])
                } else {
                    return omit(item, ['exists'])
                }
            })

            if (!filteredItems.length) return

            await copyText(JSON.stringify(filteredItems))
            notify('Items successfully exported', 'success')
        } catch (err) {
            const error = err as Error
            notify(error.message, 'error')
        }
    }

    const handleImport = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()

        try {
            const text = await pasteText()
            const parsedItems = JSON.parse(text)
            const newItems: Item[] = [...items, ...parsedItems]

            checkDuplicatesWithinArray(newItems)
            validateArrayForm(ItemSchema, newItems)

            setItems(dispatch)(newItems)
            notify('Items successfully imported', 'success')
        } catch (err) {
            console.log(err)
            const error = err as Error

            if (error.name === 'SyntaxError') {
                return notify('Syntax Error: Invalid form format', 'error')
            }

            notify(error.message, 'error')
        }
    }

    const toggleScriptMenu = (e: React.MouseEvent) => {
        e.preventDefault()
        setScriptMenuOpen(!scriptMenuOpen)
    }

    const handleCopyScript = async (
        e: React.MouseEvent,
        scriptName: string
    ) => {
        e.preventDefault()
        e.stopPropagation()

        try {
            await copyText(SCRIPTS[scriptName as keyof typeof SCRIPTS])
            notify(`${scriptName} copied to clipboard`, 'success')
            setScriptMenuOpen(false)
        } catch (err) {
            notify('Failed to copy script', 'error')
        }
    }

    return (
        <>
            <Table
                handleAddItem={handleAddItem}
                itemCount={items.length}
                tableHeadings={tableHeadings}
            >
                {items.map(
                    ({
                        title,
                        brand,
                        sku,
                        category,
                        retail,
                        resell,
                        url,
                        image,
                        id,
                    }) => (
                        <tr key={id}>
                            <TableData>
                                <ItemContainer>
                                    <ImageContainer>
                                        <Image src={image} alt={title} />
                                    </ImageContainer>
                                    <TableText>{title}</TableText>
                                </ItemContainer>
                            </TableData>
                            <TableData>
                                <TableText>{brand}</TableText>
                            </TableData>
                            <TableData>
                                <TableText>{sku}</TableText>
                            </TableData>
                            <TableData>
                                <TableText>{category}</TableText>
                            </TableData>
                            <TableData>
                                <TableText>${retail}</TableText>
                            </TableData>
                            <TableData>
                                <TableText>${resell}</TableText>
                            </TableData>
                            <TableData>
                                <Spacer>
                                    <TableText>{url}</TableText>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setSelectedItem({
                                                id,
                                                title,
                                                brand,
                                                sku,
                                                category,
                                                retail,
                                                resell,
                                                url,
                                                image,
                                            })
                                            toggleModal()
                                        }}
                                    >
                                        <EditIcon />
                                    </div>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={handleDeleteItem(title)}
                                    >
                                        <RemoveIcon />
                                    </div>
                                </Spacer>
                            </TableData>
                        </tr>
                    )
                )}
            </Table>
            <FlexContainer style={{ position: 'relative' }}>
                <FormButton color="purple" onClick={handleExport}>
                    Export items
                </FormButton>

                <FormButton color="purple" onClick={handleImport}>
                    Import items
                </FormButton>

                <div style={{ position: 'relative' }}>
                    <FormButton color="purple" onClick={toggleScriptMenu}>
                        Copy Scripts
                    </FormButton>
                    <ScriptMenu isOpen={scriptMenuOpen}>
                        {Object.keys(SCRIPTS).map((scriptName) => (
                            <ScriptButton
                                key={scriptName}
                                onClick={(e) => handleCopyScript(e, scriptName)}
                            >
                                {scriptName}
                            </ScriptButton>
                        ))}
                    </ScriptMenu>
                </div>
            </FlexContainer>
        </>
    )
}

export default ItemsTable
