import {
    Attachment,
    CustomFile,
    Instruction,
    Item,
    ReleaseRecord,
    ReleaseRecordKeys,
} from 'src/types/ReleaseRecord'
import { Action } from './StaffReleaseFormContext.actions'
import ActionTypes from './StaffReleaseFormContext.actionTypes'
import { State } from './StaffReleaseFormContext.types'

export const addItem =
    (dispatch: React.Dispatch<Action>) => (payload: Item) => {
        dispatch({ type: ActionTypes.ADD_ITEM, payload })
    }

export const addAttachment =
    (dispatch: React.Dispatch<Action>) => (payload: Attachment) => {
        dispatch({ type: ActionTypes.ADD_ATTACHMENT, payload })
    }

export const addInstruction =
    (dispatch: React.Dispatch<Action>) => (payload: Instruction) => {
        dispatch({ type: ActionTypes.ADD_INSTRUCTION, payload })
    }

export const deleteItem =
    (dispatch: React.Dispatch<Action>) => (payload: string) => {
        dispatch({ type: ActionTypes.DELETE_ITEM, payload })
    }

export const deleteAttachment =
    (dispatch: React.Dispatch<Action>) =>
    (payload: number, payload2: string) => {
        dispatch({ type: ActionTypes.DELETE_ATTACHMENT, payload, payload2 })
    }

export const deleteInstruction =
    (dispatch: React.Dispatch<Action>) => (payload: string) => {
        dispatch({ type: ActionTypes.DELETE_INSTRUCTION, payload })
    }

export const updateItem =
    (dispatch: React.Dispatch<Action>) => (title: string, value: Item) => {
        dispatch({
            type: ActionTypes.UPDATE_ITEM,
            payload: { title, value },
        })
    }

export const updateAttachment =
    (dispatch: React.Dispatch<Action>) =>
    (title: string, value: Attachment) => {
        dispatch({
            type: ActionTypes.UPDATE_ATTACHMENT,
            payload: { title, value },
        })
    }

export const updateInstruction =
    (dispatch: React.Dispatch<Action>) =>
    (title: string, value: Instruction) => {
        dispatch({
            type: ActionTypes.UPDATE_INSTRUCTION,
            payload: { title, value },
        })
    }

export const updateField =
    (dispatch: React.Dispatch<Action>) =>
    (key: ReleaseRecordKeys, value: string | number | Date | boolean) => {
        dispatch({ type: ActionTypes.UPDATE_FIELD, payload: { key, value } })
    }

export const resetForm = (dispatch: React.Dispatch<Action>) => () => {
    dispatch({ type: ActionTypes.RESET_FORM })
}

export const setFormFields =
    (dispatch: React.Dispatch<Action>) =>
    (payload: Partial<State['formState']>) => {
        dispatch({ type: ActionTypes.SET_FORM_FIELDS, payload })
    }

export const setImages =
    (dispatch: React.Dispatch<Action>) => (payload: CustomFile[]) => {
        dispatch({ type: ActionTypes.SET_IMAGES, payload })
    }

export const deleteImage =
    (dispatch: React.Dispatch<Action>) => (payload: string) => {
        dispatch({ type: ActionTypes.DELETE_IMAGE, payload })
    }

export const setSelectedRelease =
    (dispatch: React.Dispatch<Action>) => (payload: ReleaseRecord) => {
        dispatch({ type: ActionTypes.SET_SELECTED_RELEASE, payload })
    }

export const setAttachments =
    (dispatch: React.Dispatch<Action>) => (payload: Attachment[]) => {
        dispatch({ type: ActionTypes.SET_ATTACHMENTS, payload })
    }

export const setItems =
    (dispatch: React.Dispatch<Action>) => (payload: Item[]) => {
        dispatch({ type: ActionTypes.SET_ITEMS, payload })
    }
