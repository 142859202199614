import { createContext, Dispatch, useReducer } from 'react'
import useAuthContext from 'src/hooks/useAuthContext'
import { ReleaseRecord, ReleaseType } from 'src/types/ReleaseRecord'
import { Action } from './StaffReleaseFormContext.actions'
import ActionTypes from './StaffReleaseFormContext.actionTypes'
import {
    StaffReleaseFormProviderProps,
    State,
} from './StaffReleaseFormContext.types'

const date = new Date()
date.setHours(10)
date.setMinutes(0)
date.setSeconds(0)

const INITIAL_STATE: State = {
    formState: {
        sku: '',
        title: '',
        region: 'US',
        content: '',
        colorway: '',
        cover_color_hex: '',
        published: false,
        publisher_id: '',
        type: ReleaseType.SINGLE,
        release_at: date,
        cover_image: '',
        cover_transparent: false,
        images: [],
        categories: [],
        instructions: [],
        brands: [],
        keyword_group: [],

        // SINGLE
        attachments: [],
        retail_price_usd: 0,
        resell_price_usd: 0,

        // MULTI
        items: [],
    },
    selectedRelease: {} as ReleaseRecord,
}

export const StaffReleaseFormContext = createContext<State>(
    null as unknown as State
)
export const StaffDispatchContext = createContext<React.Dispatch<Action>>(
    null as unknown as Dispatch<Action>
)

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionTypes.ADD_ITEM: {
            const { formState } = state

            return {
                ...state,
                formState: {
                    ...formState,
                    items: [...formState.items, action.payload],
                },
            }
        }

        case ActionTypes.ADD_ATTACHMENT: {
            const { formState } = state

            return {
                ...state,
                formState: {
                    ...formState,
                    attachments: [...formState.attachments, action.payload],
                },
            }
        }

        case ActionTypes.ADD_INSTRUCTION: {
            const { formState } = state

            return {
                ...state,
                formState: {
                    ...formState,
                    instructions: [...formState.instructions, action.payload],
                },
            }
        }

        case ActionTypes.UPDATE_ITEM: {
            const { formState } = state
            const { title, value } = action.payload
            const items = formState.items.map((item) =>
                item.title === title ? value : item
            )

            return {
                ...state,
                formState: {
                    ...formState,
                    items,
                },
            }
        }

        case ActionTypes.UPDATE_ATTACHMENT: {
            const { formState } = state
            const { title, value } = action.payload
            const attachments = formState.attachments.map((attachment) =>
                attachment.title === title ? value : attachment
            )

            return {
                ...state,
                formState: {
                    ...formState,
                    attachments,
                },
            }
        }

        case ActionTypes.UPDATE_INSTRUCTION: {
            const { formState } = state
            const { title, value } = action.payload
            const instructions = formState.instructions.map((instruction) =>
                instruction.title === title ? value : instruction
            )

            return {
                ...state,
                formState: {
                    ...formState,
                    instructions,
                },
            }
        }

        case ActionTypes.DELETE_ITEM: {
            const { formState } = state
            const items = formState.items.filter(
                (item) => item.title !== action.payload
            )

            return {
                ...state,
                formState: {
                    ...formState,
                    items,
                },
            }
        }

        case ActionTypes.DELETE_ATTACHMENT: {
            const { formState } = state
            const attachments = formState.attachments.filter((attachment) => {
                if (action.payload > 0) {
                    return attachment.id !== action.payload
                } else {
                    return attachment.title !== action.payload2
                }
            })

            return {
                ...state,
                formState: {
                    ...formState,
                    attachments,
                },
            }
        }

        case ActionTypes.DELETE_INSTRUCTION: {
            const { formState } = state
            const instructions = formState.instructions.filter(
                (instruction) => instruction.title !== action.payload
            )

            return {
                ...state,
                formState: {
                    ...formState,
                    instructions,
                },
            }
        }

        case ActionTypes.UPDATE_FIELD: {
            const { key, value } = action.payload
            const splitKey = key.split('.')

            if (splitKey.length > 1) {
                const obj = splitKey[0] as keyof State['formState']

                return {
                    ...state,
                    formState: {
                        ...state.formState,
                        [obj]: {
                            ...(state.formState[obj] as object),
                            [splitKey[1]]: value,
                        },
                    },
                }
            }

            return {
                ...state,
                formState: {
                    ...state.formState,
                    [key]: value,
                },
            }
        }

        case ActionTypes.RESET_FORM: {
            return {
                ...INITIAL_STATE,
                formState: {
                    ...INITIAL_STATE.formState,
                    publisher_id: state.formState.publisher_id,
                },
            }
        }

        case ActionTypes.SET_FORM_FIELDS: {
            return {
                ...state,
                formState: {
                    ...INITIAL_STATE.formState,
                    ...action.payload,
                },
            }
        }

        case ActionTypes.SET_IMAGES: {
            return {
                ...state,
                formState: {
                    ...state.formState,
                    images: action.payload,
                },
            }
        }

        case ActionTypes.DELETE_IMAGE: {
            const { formState } = state
            const images = formState.images.filter(
                (image) => image.value !== action.payload
            )

            return {
                ...state,
                formState: {
                    ...state.formState,
                    images,
                },
            }
        }

        case ActionTypes.SET_SELECTED_RELEASE: {
            return {
                ...state,
                selectedRelease: action.payload,
            }
        }

        case ActionTypes.SET_ATTACHMENTS: {
            return {
                ...state,
                formState: {
                    ...state.formState,
                    attachments: action.payload,
                },
            }
        }

        case ActionTypes.SET_ITEMS: {
            return {
                ...state,
                formState: {
                    ...state.formState,
                    items: action.payload,
                },
            }
        }

        default: {
            return state
        }
    }
}

export const StaffReleaseFormProvider = ({
    children,
}: StaffReleaseFormProviderProps) => {
    const { userInfo } = useAuthContext()

    const [state, dispatch] = useReducer(reducer, {
        ...INITIAL_STATE,
        formState: {
            ...INITIAL_STATE.formState,
            publisher_id: userInfo.id,
        },
    })

    return (
        <StaffReleaseFormContext.Provider value={state}>
            <StaffDispatchContext.Provider value={dispatch}>
                {children}
            </StaffDispatchContext.Provider>
        </StaffReleaseFormContext.Provider>
    )
}
