import styled from 'styled-components'

export const ItemContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;

    > * + * {
        margin-left: 10px;
    }
`

export const ImageContainer = styled.div`
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    height: 30px;
    width: 30px;
`

export const Image = styled.img`
    width: 100%;
`

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
