import { omit } from 'lodash'
import { ReactComponent as EditIcon } from 'src/assets/edit.svg'
import { ReactComponent as RemoveIcon } from 'src/assets/remove-icon.svg'
import FormButton from 'src/components/common/FormButton/FormButton'
import Ping from 'src/components/common/Ping/Ping'
import {
    deleteAttachment,
    setAttachments,
} from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext.services'
import {
    useStaffDispatchContext,
    useStaffReleaseFormContext,
} from 'src/hooks/useStaffReleaseForm'
import { AttachmentSchema } from 'src/schema/AttachmentSchema'
import { Attachment } from 'src/types/ReleaseRecord'
import { checkDuplicatesWithinArray } from 'src/utils/checkDuplicate'
import { copyText, pasteText } from 'src/utils/clipboard'
import { notify } from 'src/utils/notify'
import { validateArrayForm } from 'src/utils/validateForm'
import Table from '../Table'
import { Spacer, TableData, TableText } from '../Table.styled'
import { tableHeadings } from './AttachmentsTable.data'
import { FlexContainer } from './AttachmentsTable.styled'
import { AttachmentsTableProps } from './AttachmentsTable.types'

const AttachmentsTable = ({
    toggleModal,
    setSelectedAttachment,
}: AttachmentsTableProps) => {
    const {
        formState: { attachments },
    } = useStaffReleaseFormContext()
    const dispatch = useStaffDispatchContext()

    const handleAddItem = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()
        setSelectedAttachment({} as Attachment)
        toggleModal()
    }

    const handleDeleteItem =
        (id: number, title: string) =>
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault()
            deleteAttachment(dispatch)(id, title)
        }

    const handleExport = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()

        try {
            const filteredAttachments = attachments.map((attachment) => {
                if (attachment.store == null) {
                    return omit(attachment, ['exists', 'store'])
                } else {
                    return omit(attachment, ['exists'])
                }
            })

            if (!filteredAttachments.length) return

            await copyText(JSON.stringify(filteredAttachments))
            notify('Attachments successfully exported', 'success')
        } catch (err) {
            const error = err as Error
            notify(error.message, 'error')
        }
    }

    const handleImport = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()

        try {
            const text = await pasteText()
            const parsedAttachments = JSON.parse(text)
            const newAttachments: Attachment[] = [
                ...attachments,
                ...parsedAttachments,
            ]

            checkDuplicatesWithinArray(newAttachments)
            validateArrayForm(AttachmentSchema, newAttachments)

            setAttachments(dispatch)(newAttachments)
            notify('Attachments successfully imported', 'success')
        } catch (err) {
            console.log(err)
            const error = err as Error

            if (error.name === 'SyntaxError') {
                return notify('Syntax Error: Invalid form format', 'error')
            }

            notify(error.message, 'error')
        }
    }

    return (
        <>
            <Table
                heading="Attachment"
                handleAddItem={handleAddItem}
                itemCount={attachments.length}
                tableHeadings={tableHeadings}
            >
                {attachments.map(
                    ({
                        title,
                        type,
                        method,
                        store,
                        locations,
                        release_at,
                        release_end,
                        url,
                        id,
                        exists,
                        region,
                    }) => (
                        <tr key={id}>
                            <TableData>
                                <TableText>
                                    <span>{title}</span>
                                    <Ping color={exists ? 'green' : 'amber'} />
                                </TableText>
                            </TableData>
                            <TableData>
                                <TableText>{type}</TableText>
                            </TableData>
                            <TableData>
                                <TableText>{method}</TableText>
                            </TableData>
                            <TableData>
                                {/* @ts-ignore */}
                                <TableText>{store?.store_name}</TableText>
                            </TableData>
                            <TableData>
                                <TableText>
                                    {/* @ts-ignore */}
                                    {locations?.length} location
                                    {/* @ts-ignore */}
                                    {locations?.length !== 1 && 's'}
                                </TableText>
                            </TableData>
                            <TableData>
                                <TableText>
                                    {new Date(release_at).toDateString()}
                                </TableText>
                            </TableData>
                            <TableData>
                                <TableText>
                                    {release_end &&
                                        new Date(release_end).toDateString()}
                                </TableText>
                            </TableData>
                            <TableData>
                                <Spacer>
                                    <TableText>{url}</TableText>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setSelectedAttachment({
                                                title,
                                                type,
                                                method,
                                                store,
                                                locations,
                                                release_at,
                                                release_end,
                                                url,
                                                id,
                                                region,
                                            })
                                            toggleModal()
                                        }}
                                    >
                                        <EditIcon />
                                    </div>
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={handleDeleteItem(id ?? 0, title ?? '')}
                                    >
                                        <RemoveIcon />
                                    </div>
                                </Spacer>
                            </TableData>
                        </tr>
                    )
                )}
            </Table>
            <FlexContainer>
                <FormButton color="purple" onClick={handleExport}>
                    Export attachments
                </FormButton>

                <FormButton color="purple" onClick={handleImport}>
                    Import attachments
                </FormButton>
            </FlexContainer>
        </>
    )
}

export default AttachmentsTable
